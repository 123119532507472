<template>
  <section>
    <div class="form-style">
      <a-input placeholder="请输入归档流程名称" v-model:value="search" style="width:365px" allowClear @keyup.enter="searchList">
        <template #prefix>
          <SearchOutlined style="color: rgba(0, 0, 0, 0.25)" />
        </template>
      </a-input>
      <a-button @click="searchList" type="primary" class="form-btn">查询</a-button>
    </div>
    <div class="business">
      <div class="bus-header">
        <div class="bus-header-left">归档流程列表</div>
        <div class="bus-header-right">
          <a-button @click="addProcess" type="primary" class="right-btn">
            <PlusOutlined />
            添加归档流程
          </a-button>
        </div>
      </div>
      <a-table :columns="columns" :dataSource="dataSource" :pagination="pagination" :loading="loading"
        @change="handleTableChange" :rowKey="(record, index) => {
          return index
        }
        " class="table">
        <template #name="{ record, text }">
          <div class="name">
            <img v-if="record.type === 1" src="@/assets/images/business/draft.png" class="draft" />
            <div class="name-right">
              <div v-if="text.length <= 17 || !text">
                {{ text || '-' }}
              </div>
              <a-tooltip v-else>
                <template #title>{{ text }}</template>
                <div class="dept-name text-ellipsis">{{ text }}</div>
              </a-tooltip>
            </div>
          </div>
        </template>
        <!-- 适用范围 -->
        <template #scope="{ text }">
          <div class="dept-name text-ellipsis" v-if="text?.length < 20">{{ text }}</div>
          <a-tooltip v-else>
            <template #title>{{ text }}</template>
            <div class="dept-name text-ellipsis">{{ text }}</div>
          </a-tooltip>
        </template>
        <!-- 文件类型 -->
        <template #fileType="{ text }">
          <div class="dept-name text-ellipsis" v-if="text?.length < 20">{{ text }}</div>
          <a-tooltip v-else>
            <template #title>{{ text }}</template>
            <div class="dept-name text-ellipsis">{{ text }}</div>
          </a-tooltip>
        </template>
        <!-- 操作 -->
        <template #action="{ record }">
          <div v-if="record.def == 0" class="action">
            <div @click="editPro(record)">查看</div>
          </div>
          <div v-else class="action">
            <div @click="archive(record)">编辑</div>
            <div v-if="record.type == 1 " @click="deletePro(record)">删除</div>
            <div v-if="record.type == 2 && record.status == 1" @click="changePro(record)">停用</div>
            <a-dropdown v-if="record.type == 2 && record.status == 2">
            <a @click.prevent style="color: rgb(24, 144, 255);">更多
              <DownOutlined style="margin-left: 4px" />
            </a>
            <template #overlay>
              <a-menu class="dropdown-menu">
                <a-menu-item>
                  <div v-if="record.type == 2 && record.status == 2" @click="changePro(record)">启用</div>
                </a-menu-item>
                <a-menu-item class="dropdown-item">
                  <div v-if=" record.status == 2" @click="deletePro(record)">删除</div>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          </div>
        </template>
      </a-table>
    </div>
    <modal v-if="visible" :visible="visible" :modalRecord="modalRecord" :modalVisible="modalVisible"></modal>
  </section>
</template>
<script setup>
import { ref, onMounted, createVNode } from 'vue'
import { paginationParams } from '@/utils/constData'
import { DownOutlined, ExclamationOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons-vue'
import { processList, delProcess, editType } from '@/apis/process'
import { getRecordsList, delRecords, statusRecords } from '@/apis/recordsProcess'
import { useRouter } from 'vue-router'
import { Modal } from 'ant-design-vue'
import { cmsNotice } from '@/utils/utils'
import { getEnums } from '@/utils/utils'
import { useStore } from 'vuex'
import { permissionCheck } from '@/apis/sealManage.js'
import { columns } from './columns'
import modal from './components/modal'
// const searchForm = ref({}) // 搜索框
const ableEdit = ref(true)
const ableDelete = ref(true)
const router = useRouter()
const search = ref('') // 搜索名称
const dataSource = ref([]) // 表格数据
const pagination = ref({ ...paginationParams })
const loading = ref(false)
const timeSort = ref('')
const searchObj = ref({
  pageIndex: pagination.value.current,
  pageSize: pagination.value.pageSize
})
const visible = ref(false)
const modalRecord = ref({})
const store = useStore()
onMounted(() => {
  getList()
  // getPermissionCheck()
})

const getList = () => {
  loading.value = true
  // 获取列表
  const params = {
    pageIndex: pagination.value.current,
    pageSize: pagination.value.pageSize,
    search: search.value,
    orderByUpdateTime: timeSort.value
  }
  getRecordsList(params).then(res => {
    console.log(res)
    dataSource.value = res.data.map(item => {
      let fileType
      if (item.fileTypeList != null) {
        fileType = item.fileTypeList.map(value => value.typeName).join('、')
      } else {
        fileType = '-'
      }
      let scope
      if (item.scopeList != null) {
        scope = item.scopeList.map(value => value.dataName).join('、')
      } else {
        scope = '所有机构'
      }
      return {
        ...item,
        fileType,
        scope
      }
    })
    pagination.value.total = res.totalItem
    loading.value = false
  })
}

// 搜索框查询
const searchList = () => {
  console.log('搜索', search.value)
  pagination.value.current = 1
  getList()
}

// 添加流程名称
const addProcess = () => {
  // console.log('跳转页面')
  // store.commit('resetProcessData', { type: 1 })
  router.push({
    path: '/recordsBusinessAdd',
    query: {
      type: 1
    }
  })
}

//修改
const archive = record => {
  router.push({
    path: '/recordsBusinessAdd',
    query: {
      enterpriseId: record.processId,
      type: record.type
    }
  })
}

// 切换页数
const handleTableChange = ({ current, pageSize }, filters, { order }) => {
  // console.log(filters, { order });
  timeSort.value = ''
  if (order == 'ascend') {
    timeSort.value = 1
  }
  pagination.value.current = current
  pagination.value.pageSize = pageSize
  dataSource.value = []
  getList()
}

const changePro = record => {
  console.log('启用/停用', record.processId)
  if (record.status === 2) {
    statusRecords(1, record.processId).then(res => {
      console.log(res)
      if (res.success) {
        cmsNotice('success', '操作成功')
        getList()
      }
    })
    return
  }
  Modal.confirm({
    title: '是否停用！',
    width: '400px',
    content: '请确认是否停用，停用后对已发起的业务不影响',
    centered: true,
    // confirmLoading: loading.value,
    icon: createVNode(ExclamationOutlined),
    onOk: () => {
      // 确认返回
      statusRecords(2, record.processId).then(res => {
        console.log(res)
        if (res.success) {
          cmsNotice('success', '操作成功')
          getList()
        }
      })
    }
  })
}

//查看弹窗
const editPro = record => {
  visible.value = true
  console.log(visible.value)
  modalRecord.value = record
}
const modalVisible = () => {
  visible.value = false
}

const deletePro = record => {
  console.log('删除', record)
  Modal.confirm({
    title: '是否删除！',
    width: '400px',
    content: '删除后无法恢复，是否删除？',
    centered: true,
    // confirmLoading: loading.value,
    icon: createVNode(ExclamationOutlined),
    onOk: () => {
      // 确认返回
      delRecords({
        // enterpriseId: JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.enterpriseId,
        processId: record.processId
      })
        .then(res => {
          console.log(res)
          if (res.success) {
            cmsNotice('success', '删除成功')
            const { current, pageSize, total } = pagination.value
            total % (current * pageSize - pageSize) === 1 && (pagination.value.current -= 1)
            getList()
          } else {
            cmsNotice('err', `删除失败:${res.data}`)
          }
        })
        .catch(err => {
          cmsNotice('err', err)
        })
    }
  })
}

// 获取功能权限
// const getPermissionCheck = async () => {
//   await permissionCheck({
//     code: 'PC_APPROVAL_PROCESS_MENU_UPDATE'
//   })
//     .then(res => {
//       console.log('是否有编辑权限', res)
//       ableEdit.value = res.data
//     })
//     .catch(err => { })
//   await permissionCheck({
//     code: 'PC_APPROVAL_PROCESS_MENU_DEL'
//   })
//     .then(res => {
//       console.log('是否有删除权限', res)
//       ableDelete.value = res.data
//     })
//     .catch(err => { })
//   console.log('操作是否显示', ableEdit.value, ableDelete.value)
// if (ableEdit.value || ableDelete.value) {
//   columns.value.push({
//     title: '操作',
//     dataIndex: 'action',
//     slots: { customRender: 'action' },
//     className: 'table-td'
//   })
// }
// }
</script>

<style scoped lang="less">
.form-style {
  display: flex;
  padding: 16px;
  margin: 24px;

  .form-btn {
    margin-left: 8px;
    border-radius: 2px;
  }
}

.business {
  padding: 24px;
  background-color: #fff;
  color: #000;
  margin: 24px;

  .bus-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    vertical-align: middle;

    .right-btn {
      border-radius: 2px;
    }
  }

  .table {
    .draft {
      position: absolute;
      width: 36px;
      height: 36px;
      top: -16px;
      left: -16px;
    }

    .name {
      display: flex;
      position: relative;

      // position: relative;
      .name-right {
       

        .lang-name {
          max-width: 250px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
        }

        .name-remark {
          color: #999999;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    .lang {
      max-width: 120px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    .enable {
      margin-right: 12px;
    }

    .more,
    .enable,
    .action {
      display: flex;

      div {
        color: #1890ff;
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .type {
      cursor: pointer;
      display: inline-block;
      color: #1890ff;
    }
  }
}
</style>
<style lang="scss" scoped>
.lang-name-pop {
  .ant-popover-inner-content {
    max-width: 300px;
  }
}

.table-td {
  display: table-cell;
  vertical-align: middle;
}
</style>
<style lang="less" scoped>
.dept-name {
  max-width: 20em;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown-menu {
  width: 94px;
  text-align: center;
}
</style>
